<template>
  <!-- 新增新闻分类 -->
  <div>
    <!-- 填写表单 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="分类标题" prop="categoryName">
        <el-input
          v-model="ruleForm.categoryName"
          placeholder="请输入标题"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="ruleForm.whetherNot">
          <el-radio :label="1">关闭</el-radio>
          <el-radio :label="2">启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="序号" prop="serialNumber">
        <el-input
          v-model.number="ruleForm.serialNumber"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          placeholder="请输入序号"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="ruleForm.remarks"
          type="textarea"
          placeholder="请输入备注"
          :rows="4"
          style="width: 600px"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
          <el-button type="warning" @click="goAddCate">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        categoryName: "", // 分类名称
        whetherNot: 1, // 是否启用
        serialNumber: "", // 序号
        remarks: "", // 备注
      },
      //   验证规则
      rules: {
        categoryName: [
          { required: true, message: "请输入分类标题", trigger: "blur" },
        ],
        serialNumber: [
          { required: true, message: "请输入序号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    goAddCate() {
      this.axios
        .post("/admin/consultType/addConsultType", {
          status: this.ruleForm.whetherNot,
          remark: this.ruleForm.remarks,
          sort: this.ruleForm.serialNumber,
          typeName: this.ruleForm.categoryName,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            setTimeout(() => {
              this.getBack();
            }, 500);
            this.ruleForm.categoryName = "";
            this.ruleForm.remarks = "";
            this.ruleForm.serialNumber = "";
          }
        })
        .catch((err) => {});
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    // 保存
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
